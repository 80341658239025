import PKief_default from './PKief/file.svg';
import PKief_html from './PKief/html.svg';
import PKief_markdown from './PKief/markdown.svg';
import PKief_css from './PKief/css.svg';
import PKief_git from './PKief/git.svg';
import PKief_sass from './PKief/sass.svg';
import PKief_json from './PKief/json.svg';
import PKief_xml from './PKief/xml.svg';
import PKief_image from './PKief/image.svg';
import PKief_javascript from './PKief/javascript.svg';
import PKief_react from './PKief/react.svg';
import PKief_react_ts from './PKief/react_ts.svg';
import PKief_settings from './PKief/settings.svg';
import PKief_typescript from './PKief/typescript.svg';
import PKief_typescript_def from './PKief/typescript-def.svg';
import PKief_pdf from './PKief/pdf.svg';
import PKief_table from './PKief/table.svg';
import PKief_csharp from './PKief/csharp.svg';
import PKief_database from './PKief/database.svg';
import PKief_zip from './PKief/zip.svg';
import PKief_java from './PKief/java.svg';
import PKief_c from './PKief/c.svg';
import PKief_h from './PKief/h.svg';
import PKief_cpp from './PKief/cpp.svg';
import PKief_hpp from './PKief/hpp.svg';
import PKief_go from './PKief/go.svg';
import PKief_python from './PKief/python.svg';
import PKief_word from './PKief/word.svg';
import PKief_php from './PKief/php.svg';
import PKief_ruby from './PKief/ruby.svg';
import PKief_fsharp from './PKief/fsharp.svg';
import PKief_swift from './PKief/swift.svg';
import PKief_video from './PKief/video.svg';
import PKief_coffee from './PKief/coffee.svg';
import PKief_document from './PKief/document.svg';
import PKief_graphql from './PKief/graphql.svg';
import PKief_rust from './PKief/rust.svg';
import PKief_xaml from './PKief/xaml.svg';
import PKief_haskell from './PKief/haskell.svg';
import PKief_kotlin from './PKief/kotlin.svg';
import PKief_lua from './PKief/lua.svg';
import PKief_clojure from './PKief/clojure.svg';
import PKief_groovy from './PKief/groovy.svg';
import PKief_r from './PKief/r.svg';
import PKief_dart from './PKief/dart.svg';
import PKief_actionscript from './PKief/actionscript.svg';
import PKief_cmake from './PKief/cmake.svg';
import PKief_javascript_map from './PKief/javascript-map.svg';
import PKief_css_map from './PKief/css-map.svg';
import PKief_perl from './PKief/perl.svg';
import PKief_haxe from './PKief/haxe.svg';
import PKief_test_ts from './PKief/test-ts.svg';
import PKief_test_js from './PKief/test-js.svg';
import PKief_elixir from './PKief/elixir.svg';
import PKief_livescript from './PKief/livescript.svg';
import PKief_erlang from './PKief/erlang.svg';
import PKief_cake from './PKief/cake.svg';
import PKief_sbt from './PKief/sbt.svg';
import PKief_nodejs from './PKief/nodejs.svg';
import PKief_npm from './PKief/npm.svg';
import PKief_yarn from './PKief/yarn.svg';
import PKief_babel from './PKief/babel.svg';
import PKief_readme from './PKief/readme.svg';
import PKief_authors from './PKief/authors.svg';
import PKief_jest from './PKief/jest.svg';
import PKief_changelog from './PKief/changelog.svg';
import PKief_scala from './PKief/scala.svg';
import PKief_folder_images from './PKief/folder-images.svg';
import PKief_folder_javascript from './PKief/folder-javascript.svg';
import PKief_folder_typescript from './PKief/folder-typescript.svg';
import PKief_folder_markdown from './PKief/folder-markdown.svg';
import PKief_folder_php from './PKief/folder-php.svg';
import PKief_folder_python from './PKief/folder-python.svg';
import PKief_folder_secure from './PKief/folder-secure.svg';
import PKief_folder_src from './PKief/folder-src.svg';
import PKief_folder_video from './PKief/folder-video.svg';
import PKief_folder_images_open from './PKief/folder-images-open.svg';
import PKief_folder_javascript_open from './PKief/folder-javascript-open.svg';
import PKief_folder_typescript_open from './PKief/folder-typescript-open.svg';
import PKief_folder_markdown_open from './PKief/folder-markdown-open.svg';
import PKief_folder_php_open from './PKief/folder-php-open.svg';
import PKief_folder_python_open from './PKief/folder-python-open.svg';
import PKief_folder_secure_open from './PKief/folder-secure-open.svg';
import PKief_folder_src_open from './PKief/folder-src-open.svg';
import PKief_folder_video_open from './PKief/folder-video-open.svg';
// import Other_scala from './other/scala.svg';
// import Logo_GithubLight32 from './other/github-light-32.png';
import Logo_GithubLight64 from './other/github-light-64.png';
import Logo_Google from './other/google.svg';
import Logo_Unsplash from './other/unsplash.svg';
import Logo_UnsplashWhite from './other/unsplash_white.svg';
import Logo_Maven from './other/maven.svg';
import Logo_Protobuf from './other/protobuf.svg';
export const FileIcons = {
    default: PKief_default,
    ['.git']: PKief_git,
    ['.html']: PKief_html,
    ['.htm']: PKief_html,
    ['.xhtml']: PKief_html,
    ['.md']: PKief_markdown,
    ['.markdown']: PKief_markdown,
    ['.css']: PKief_css,
    ['.sass']: PKief_sass,
    ['.scss']: PKief_sass,
    ['.json']: PKief_json,
    ['.xml']: PKief_xml,
    ['.plist']: PKief_xml,
    ['.xsd']: PKief_xml,
    ['.dtd']: PKief_xml,
    ['.xsl']: PKief_xml,
    ['.xslt']: PKief_xml,
    ['.nuspec']: PKief_xml,
    ['.png']: PKief_image,
    ['.jpeg']: PKief_image,
    ['.jpg']: PKief_image,
    ['.gif']: PKief_image,
    ['.svg']: PKief_image,
    ['.ico']: PKief_image,
    ['.tif']: PKief_image,
    ['.tiff']: PKief_image,
    ['.psd']: PKief_image,
    ['.bmp']: PKief_image,
    ['.cur']: PKief_image,
    ['.img']: PKief_image,
    ['.pic']: PKief_image,
    ['.raw']: PKief_image,
    ['.webp']: PKief_image,
    ['.eps']: PKief_image,
    ['.js']: PKief_javascript,
    ['.jsx']: PKief_react,
    ['.tsx']: PKief_react_ts,
    ['.ini']: PKief_settings,
    ['.config']: PKief_settings,
    ['.conf']: PKief_settings,
    ['.cfg']: PKief_settings,
    ['.ts']: PKief_typescript,
    ['.d.ts']: PKief_typescript_def,
    ['.pdf']: PKief_pdf,
    ['.xlsx']: PKief_table,
    ['.xls']: PKief_table,
    ['.csv']: PKief_table,
    ['.tsv']: PKief_table,
    ['.pdb']: PKief_database,
    ['.sql']: PKief_database,
    ['.mdb']: PKief_database,
    ['.sqlite']: PKief_database,
    ['.pgsql']: PKief_database,
    ['.postgres']: PKief_database,
    ['.psql']: PKief_database,
    ['.cs']: PKief_csharp,
    ['.csx']: PKief_csharp,
    ['.zip']: PKief_zip,
    ['.tar']: PKief_zip,
    ['.gz']: PKief_zip,
    ['.gzip']: PKief_zip,
    ['.7z']: PKief_zip,
    ['.rar']: PKief_zip,
    ['.tgz']: PKief_zip,
    ['.java']: PKief_java,
    ['.jsp']: PKief_java,
    ['.c']: PKief_c,
    ['.m']: PKief_c,
    ['.h']: PKief_h,
    ['.cpp']: PKief_cpp,
    ['.cc']: PKief_cpp,
    ['.mm']: PKief_cpp,
    ['.cxx']: PKief_cpp,
    ['.hpp']: PKief_hpp,
    ['.go']: PKief_go,
    ['.py']: PKief_python,
    ['.php']: PKief_php,
    ['.doc']: PKief_word,
    ['.docx']: PKief_word,
    ['.rtf']: PKief_word,
    ['.ruby']: PKief_ruby,
    ['.fs']: PKief_fsharp,
    ['.fsx']: PKief_fsharp,
    ['.fsi']: PKief_fsharp,
    ['.swift']: PKief_swift,
    ['.webm']: PKief_video,
    ['.mkv']: PKief_video,
    ['.flv']: PKief_video,
    ['.vob']: PKief_video,
    ['.ogv']: PKief_video,
    ['.ogg']: PKief_video,
    ['.gifv']: PKief_video,
    ['.avi']: PKief_video,
    ['.mov']: PKief_video,
    ['.qt']: PKief_video,
    ['.wmv']: PKief_video,
    ['.yuv']: PKief_video,
    ['.rm']: PKief_video,
    ['.rmvb']: PKief_video,
    ['.mp4']: PKief_video,
    ['.m4v']: PKief_video,
    ['.mpg']: PKief_video,
    ['.mp2']: PKief_video,
    ['.mpeg']: PKief_video,
    ['.mpe']: PKief_video,
    ['.mpv']: PKief_video,
    ['.m2v']: PKief_video,
    ['.coffee']: PKief_coffee,
    ['.txt']: PKief_document,
    ['.graphql']: PKief_graphql,
    ['.gql']: PKief_graphql,
    ['.rs']: PKief_rust,
    ['.xaml']: PKief_xaml,
    ['.hs']: PKief_haskell,
    ['.kt']: PKief_kotlin,
    ['.kts']: PKief_kotlin,
    ['.lua']: PKief_lua,
    ['.clj']: PKief_clojure,
    ['.cljs']: PKief_clojure,
    ['.groovy']: PKief_groovy,
    ['.r']: PKief_r,
    ['.dart']: PKief_dart,
    ['.as']: PKief_actionscript,
    ['.cmake']: PKief_cmake,
    ['.js.map']: PKief_javascript_map,
    ['.mjs.map']: PKief_javascript_map,
    ['.css.map']: PKief_css_map,
    ['.pl']: PKief_perl,
    ['.pm']: PKief_perl,
    ['.hx']: PKief_haxe,
    ['.spec.ts']: PKief_test_ts,
    ['.test.ts']: PKief_test_ts,
    ['.ts.snap']: PKief_test_ts,
    ['.spec.js']: PKief_test_js,
    ['.test.js']: PKief_test_js,
    ['.js.snap']: PKief_test_js,
    ['.ex']: PKief_elixir,
    ['.exs']: PKief_elixir,
    ['.eex']: PKief_elixir,
    ['.ls']: PKief_livescript,
    ['.erl']: PKief_erlang,
    ['.cake']: PKief_cake,
    ['.sbt']: PKief_sbt,
    ['package.json']: PKief_nodejs,
    ['.npm']: PKief_npm,
    ['.yarn']: PKief_yarn,
    ['.babel']: PKief_babel,
    ['readme.md']: PKief_readme,
    ['readme.txt']: PKief_readme,
    ['readme']: PKief_readme,
    ['changelog.md']: PKief_changelog,
    ['changelog.txt']: PKief_changelog,
    ['changelog']: PKief_changelog,
    ['authors.md']: PKief_authors,
    ['authors.txt']: PKief_authors,
    ['chanauthorsgelog']: PKief_authors,
    ['.jest']: PKief_jest,
    // 'jest.setup.js', 'jest.setup.ts', 'jest.json', '.jestrc', 'jest.teardown.js
    ['.scala']: PKief_scala,
    ['.pfdoc']: PKief_document,
    ['.proto']: Logo_Protobuf
};
export const FolderIcons = {
    images: PKief_folder_images,
    javascript: PKief_folder_javascript,
    typescript: PKief_folder_typescript,
    markdown: PKief_folder_markdown,
    php: PKief_folder_php,
    python: PKief_folder_python,
    secure: PKief_folder_secure,
    src: PKief_folder_src,
    video: PKief_folder_video,
    images_open: PKief_folder_images_open,
    javascript_open: PKief_folder_javascript_open,
    typescript_open: PKief_folder_typescript_open,
    markdown_open: PKief_folder_markdown_open,
    php_open: PKief_folder_php_open,
    python_open: PKief_folder_python_open,
    secure_open: PKief_folder_secure_open,
    src_open: PKief_folder_src_open,
    video_open: PKief_folder_video_open
};
// We suppose that longer strings are more required than those short, plus we add dot to have extension there
const fileIcons = Object.keys(FileIcons).sort((a, b) => b.length - a.length);
export function findFileIcon(name) {
    const fl = name.toLowerCase();
    for (const f of fileIcons) {
        if (fl.endsWith(f)) {
            return FileIcons[f];
        }
    }
    return FileIcons.default;
}
const folderFileTypes = {
    ['.png']: 'images',
    ['.jpeg']: 'images',
    ['.jpg']: 'images',
    ['.gif']: 'images',
    ['.svg']: 'images',
    ['.ico']: 'images',
    ['.tif']: 'images',
    ['.tiff']: 'images',
    ['.psd']: 'images',
    ['.bmp']: 'images',
    ['.cur']: 'images',
    ['.img']: 'images',
    ['.pic']: 'images',
    ['.raw']: 'images',
    ['.webp']: 'images',
    ['.eps']: 'images',
    ['.js']: 'javascript',
    ['.ts']: 'typescript',
    ['.md']: 'markdown',
    ['.php']: 'php',
    ['.py']: 'python',
    ['.webm']: 'video',
    ['.mkv']: 'video',
    ['.flv']: 'video',
    ['.vob']: 'video',
    ['.ogv']: 'video',
    ['.ogg']: 'video',
    ['.gifv']: 'video',
    ['.avi']: 'video',
    ['.mov']: 'video',
    ['.qt']: 'video',
    ['.wmv']: 'video',
    ['.yuv']: 'video',
    ['.rm']: 'video',
    ['.rmvb']: 'video',
    ['.mp4']: 'video',
    ['.m4v']: 'video',
    ['.mpg']: 'video',
    ['.mp2']: 'video',
    ['.mpeg']: 'video',
    ['.mpe']: 'video',
    ['.mpv']: 'video',
    ['.m2v']: 'video'
};
export function findFolderIcon(open, files) {
    if (!files) {
        return undefined;
    }
    // Secure and Src folder icons are not supported
    const types = {};
    files.forEach(f => {
        const extension = f.substr(f.lastIndexOf('.'));
        const type = folderFileTypes[extension] || 'default';
        types[type] = true;
    });
    const keys = Object.keys(types);
    if (keys.length === 0 || keys.length > 1) {
        return undefined;
    }
    return open ? FolderIcons[keys[0] + '_open'] : FolderIcons[keys[0]];
}
export const LogoIcons = {
    github: Logo_GithubLight64,
    google: Logo_Google,
    unsplash: Logo_Unsplash,
    unsplashWhite: Logo_UnsplashWhite,
    maven: Logo_Maven
};
